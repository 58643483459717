import { ChangeEvent, FC, memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch } from "app/store";
import { lockModalBack, unlockModalBack } from "common/common";
import { DEFAULT_USER_ICON } from "common/constants";
import PortalBtn from "components/Common/PortalBtn";
import ProfileName from "components/Profiles/ProfileDetailModal/ProfileName";
import ProfileBasicInfo from "components/Profiles/ProfileDetailModal/ProfileBasicInfo";
import ProfileBoxes from "components/Profiles/ProfileDetailModal/ProfileBoxes";
import { EmployeeDetail } from "ducks/employee/type";
import {
  fetchAsyncGetEmployeeDetail,
  selectEmployeeDetail,
  selectIsDetailLoading,
  resetEmployeeDetail,
} from "ducks/employee/slice";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "components/Shadcn/Dialog";
import { Skeleton } from "components/Shadcn/Skeleton";

type Props = {
  isOpen: boolean;
  closeModal: VoidFunction;
  id: string;
};

/**
 * 社員詳細モーダル
 * @param Props
 * @returns
 */
const ProfileDetailModal: FC<Props> = ({ isOpen, closeModal, id }) => {
  const dispatch = useDispatch<AppDispatch>();

  const [scrollPos, setScrollPos] = useState<number>(0);

  const employee: EmployeeDetail = useSelector(selectEmployeeDetail);
  const isDetailLoading = useSelector(selectIsDetailLoading);

  /** モーダルオープン時のスクロール位置取得とリセット処理 */
  useEffect(() => {
    if (isOpen) {
      setScrollPos(window.pageYOffset);
      dispatch(resetEmployeeDetail());
      dispatch(fetchAsyncGetEmployeeDetail(id));
    }
  }, [isOpen, dispatch, id]);

  /** モーダルの背景固定 */
  useEffect(() => {
    if (isOpen) {
      lockModalBack(scrollPos);
    } else {
      unlockModalBack(scrollPos);
    }
  }, [isOpen, scrollPos]);

  return (
    <>
      <Dialog open={isOpen} onOpenChange={closeModal}>
        <DialogContent
          className="max-w-4xl lg:w-[900px] md:w-[700px] sm:w-[99%] h-[99%] px-0"
          onClick={(e) => e.stopPropagation()}
        >
          <DialogHeader>
            <DialogTitle></DialogTitle>
            <DialogDescription></DialogDescription>
          </DialogHeader>
          <div className="max-h-full px-5 py-0 overflow-x-hidden overflow-y-scroll">
            {isDetailLoading ? (
              <div>
                <div className="flex justify-center max-w-[99%] sm:h-[300px] h-[200px]">
                  <Skeleton className="w-[200px] h-[200px] sm:w-[300px] sm:h-[300px] rounded-full" />
                </div>

                <div className="mt-4 flex justify-center items-center space-x-2">
                  <Skeleton className="h-10 w-1/3" />
                  <Skeleton className="h-10 w-1/3" />
                </div>

                <div className="mt-8 space-y-8">
                  <Skeleton className="h-6 w-full" />
                  <Skeleton className="h-6 w-full" />
                  <Skeleton className="h-6 w-full" />
                </div>

                <div className="mt-16">
                  <Skeleton className="h-6 w-full" />
                </div>

                <div className="mb-[30px] mt-10">
                  <Skeleton className="h-32 w-full" />
                </div>

                <div className="mb-[30px] mt-5">
                  <Skeleton className="h-32 w-full" />
                </div>

                <div className="mb-[30px] mt-5">
                  <Skeleton className="h-[300px] w-full" />
                </div>
              </div>
            ) : (
              <div>
                <div className="flex justify-center max-w-[99%] sm:h-[300px] h-[200px]">
                  <img
                    src={employee.picture}
                    alt="thumbnail"
                    onError={(e: ChangeEvent<HTMLImageElement>) =>
                      (e.target.src = DEFAULT_USER_ICON)
                    }
                  />
                </div>

                <div className="mt-2.5">
                  <ProfileName
                    firstName={employee.firstName}
                    firstNameKana={employee.firstNameKana}
                    lastName={employee.lastName}
                    lastNameKana={employee.lastNameKana}
                  />
                </div>

                <div className="mt-5">
                  <ProfileBasicInfo
                    staffId={employee.staffId}
                    jobId={employee.jobId}
                    birthday={employee.birthday}
                    hireDate={employee.hireDate}
                    email={employee.email}
                    stateId={employee.stateId}
                    city={employee.city}
                  />
                </div>

                <div className="mb-[30px]">
                  <ProfileBoxes
                    hobby={employee.hobby}
                    skills={employee.skills}
                    freeSpace={employee.freeSpace}
                    skillSheet={employee.skillSheet}
                  />
                </div>
              </div>
            )}
            <div className="flex lg:justify-end lg:mt-[30px] lg:mr-[5%] md:justify-end md:mt-[30px] md:mr-[5%] justify-center mr-0">
              <PortalBtn clickFunc={closeModal}>閉じる</PortalBtn>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default memo(ProfileDetailModal);
