import { FC, memo, useRef, useState } from "react";
import { ArrowUp } from "lucide-react";
import { Textarea } from "../Shadcn/Textarea";
import { useFaqBotContext } from "contexts/FaqBotContext";

interface MessageInputProps {
  isMobile: boolean;
}

const MessageInput: FC<MessageInputProps> = memo(({ isMobile }) => {
  const { inputValue, isTyping, handleInputChange, handleSendMessage } =
    useFaqBotContext();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [isComposing, setIsComposing] = useState(false);
  const isDisabled = inputValue.trim() === "" || isTyping;
  // PCの場合Enterキーで送信、Shift+Enterで改行
  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (isMobile) {
      return;
    }
    if (e.key === "Enter" && !e.shiftKey && !isComposing) {
      e.preventDefault();
      if (!isDisabled) {
        handleSendMessage(inputValue);
        resetTextareaHeight();
      }
    }
  };

  // 自動で高さを調整（一定の行数を超えたらスクロール）
  const handleInput = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };
  // textareaの高さをリセット
  const resetTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.minHeight = "3.5rem";
    }
  };

  return (
    <div className="sticky bottom-4 bg-white mx-4 flex items-end sm:mx-16 pt-4">
      <Textarea
        ref={textareaRef}
        className="flex-grow max-h-40 min-h-14 rounded-xl p-3 bg-gray-100 focus:outline-none focus:ring-0 border-none resize-none overflow-y-auto text-base"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onInput={handleInput}
        placeholder="質問を入力してください..."
        rows={1}
        // 日本語入力の判定
        onCompositionStart={() => setIsComposing(true)}
        onCompositionEnd={() => setIsComposing(false)}
      />
      <button
        className={`absolute right-4 -bottom-2 transform -translate-y-1/2 rounded-full p-2 transition-colors bg-primary text-white hover:opacity-80 disabled:opacity-50 disabled:cursor-not-allowed`}
        onClick={() => {
          handleSendMessage(inputValue);
          resetTextareaHeight();
        }}
        disabled={isDisabled}
      >
        <ArrowUp className="h-5 w-5" />
      </button>
    </div>
  );
});

export default MessageInput;
