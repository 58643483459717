import { FC, useMemo, useEffect, useState } from "react";
import { PAGES } from "common/PAGES";
import Layout from "components/Layout";
import MessageList from "components/FaqBot/MessageList";
import MessageInput from "components/FaqBot/MessageInput";
import { FaqBotProvider } from "contexts/FaqBotContext";
import { isMobileDevice } from "common/common";

const FaqBot: FC = () => {
  const pageName: string = useMemo(() => PAGES.FAQ_BOT.NAME, []);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  useEffect(() => {
    setIsMobile(isMobileDevice());
    if (!isMobile) {
      // スクロールバーを非表示にする
      document.body.style.overflow = "hidden";
    }
    // クリーンアップ関数でスクロールバーを元に戻す
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isMobile]);
  return (
    <Layout pageName={pageName} showFooter={false}>
      <FaqBotProvider>
        <div className="flex flex-col h-screen max-w-screen-lg mx-auto bg-white shadow-lg pb-6">
          <MessageList />
          <MessageInput isMobile={isMobile} />
        </div>
      </FaqBotProvider>
    </Layout>
  );
};

export default FaqBot;
