import { FC, memo } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { useFaqBotContext } from "contexts/FaqBotContext";

const MessageMarkdownTyping: FC = memo(() => {
  const { currentResponse } = useFaqBotContext();
  return (
    <span className="text-gray-900 mb-4">
      <ReactMarkdown
        remarkPlugins={[remarkGfm as any]}
        components={{
          hr: () => null,
        }}
        className="prose"
      >
        {currentResponse}
      </ReactMarkdown>
      <span className="animate-pulse">▋</span>
    </span>
  );
});

export default MessageMarkdownTyping;
