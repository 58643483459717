import { ReactNode } from "react";
import Home from "containers/Home";
import Profiles from "containers/Profiles";
import WelfareInfo from "containers/WelfareInfo";
import Error from "containers/Error";
import ProfileEdit from "containers/ProfileEdit";
import Activities from "containers/Activities";
import ActivityAdd from "containers/ActivityAdd";
import ActivityReportDetail from "containers/ActivityReportDetail";
import WorkRegulations from "containers/WorkRegulations";
import WelfareEdit from "containers/WelfareEdit";
import ActivityUpdate from "containers/ActivityUpdate";
import FaqBot from "containers/FaqBot";

export type PageType = {
  IS_ON_HEADER: boolean;
  NAME: string;
  PATH: string;
  CONTAINER: ReactNode;
};

type PagesType = {
  ERROR: PageType;
  HOME: PageType;
  PROFILES: PageType;
  WELFARE_INFO: PageType;
  FAQ_BOT: PageType;
  ACTIVITY_REPORT: PageType;
  ACTIVITY_REPORT_ADD: PageType;
  WORK_REGULATION: PageType;
  PROFILE_EDIT: PageType;
  ACTIVITY_REPORT_DETAIL: PageType;
  ACTIVITY_REPORT_UPDATE: PageType;
  WELFARE_EDIT: PageType;
};

/**
 * ページ設定
 */
export const PAGES: PagesType = {
  ERROR: {
    IS_ON_HEADER: true,
    NAME: "Error",
    PATH: "/*",
    CONTAINER: <Error />,
  },
  HOME: {
    IS_ON_HEADER: false,
    NAME: "ホーム",
    PATH: "/",
    CONTAINER: <Home />,
  },
  PROFILES: {
    IS_ON_HEADER: true,
    NAME: "社員一覧",
    PATH: "/profiles",
    CONTAINER: <Profiles />,
  },
  WELFARE_INFO: {
    IS_ON_HEADER: true,
    NAME: "福利厚生",
    PATH: "/welfare-info",
    CONTAINER: <WelfareInfo />,
  },
  FAQ_BOT: {
    IS_ON_HEADER: true,
    NAME: "FAQ",
    PATH: "/faq",
    CONTAINER: <FaqBot />,
  },
  ACTIVITY_REPORT: {
    IS_ON_HEADER: true,
    NAME: "活動報告",
    PATH: "/activities",
    CONTAINER: <Activities />,
  },
  ACTIVITY_REPORT_ADD: {
    IS_ON_HEADER: false,
    NAME: "活動報告（投稿ページ）",
    PATH: "/activitiy-add",
    CONTAINER: <ActivityAdd />,
  },
  WORK_REGULATION: {
    IS_ON_HEADER: true,
    NAME: "社内規定",
    PATH: "/work-regulations",
    CONTAINER: <WorkRegulations />,
  },
  PROFILE_EDIT: {
    IS_ON_HEADER: false,
    NAME: "プロフィール編集",
    PATH: "/profile-edit",
    CONTAINER: <ProfileEdit />,
  },
  ACTIVITY_REPORT_DETAIL: {
    IS_ON_HEADER: false,
    NAME: "活動報告詳細",
    PATH: "/activity-detail",
    CONTAINER: <ActivityReportDetail />,
  },
  ACTIVITY_REPORT_UPDATE: {
    IS_ON_HEADER: false,
    NAME: "活動報告更新",
    PATH: "/activity-update",
    CONTAINER: <ActivityUpdate />,
  },
  WELFARE_EDIT: {
    IS_ON_HEADER: false,
    NAME: "福利厚生編集",
    PATH: "/welfare-edit",
    CONTAINER: <WelfareEdit />,
  },
};
