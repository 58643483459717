import { Auth } from "aws-amplify";
import { SignatureV4 } from "@aws-sdk/signature-v4";
import { Sha256 } from "@aws-crypto/sha256-js";
import { HttpRequest } from "@aws-sdk/protocol-http";

/**
 * 認証付きリクエストを作成し、ストリーミングレスポンスを処理
 */
export const sendAuthenticatedRequest = async (
  content: string,
  threadId: string,
  processStreamedResponse: (chunk: string) => void
) => {
  try {
    const credentials = await Auth.currentCredentials();
    const signer = new SignatureV4({
      region: "ap-northeast-1",
      service: "lambda",
      sha256: Sha256,
      credentials,
    });

    const requestUrl = process.env.REACT_APP_AI_LAMBDA_URL || "";
    const hostname = requestUrl.replace(/^https:\/\//, "");
    const query: { [key: string]: string } = { question: content };
    if (threadId) query.threadId = threadId;

    const req = await signer.sign(
      new HttpRequest({
        method: "GET",
        protocol: "https:",
        path: "/",
        hostname: hostname,
        headers: { host: hostname },
        query: query,
      })
    );

    const response = await fetch(
      `${requestUrl}?${new URLSearchParams(query).toString()}`,
      {
        method: req.method,
        headers: req.headers as HeadersInit,
      }
    );

    if (!response.body) throw new Error("No response body");

    const reader = response.body.getReader();
    const decoder = new TextDecoder("utf-8");
    let done = false;
    let buffer = "";

    while (!done) {
      const { value, done: streamDone } = await reader.read();
      done = streamDone;
      if (value) {
        buffer += decoder.decode(value, { stream: true });
        const parts = buffer.split("\n\n");
        parts.slice(0, -1).forEach((part) => {
          if (part.trim()) {
            processStreamedResponse(part.trim());
          }
        });
        buffer = parts[parts.length - 1];
      }
    }
  } catch (error) {
    console.error("Error during sendAuthenticatedRequest", error);
    throw error;
  }
};

/**
 * 不要な[source]形式の文字列を削除
 * @param text 対象となるテキスト
 * @returns クリーンアップされたテキスト
 */
export const removeSourceReferences = (text: string): string => {
  return text.replace(/【\d+:\d+†source】/g, "");
};
