import { FC, ReactNode } from "react";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { PageType } from "common/PAGES";
import Footer from "components/Footer";
import Header from "components/Header";
import Breadcrumbs from "components/Breadcrumbs";
import { GoogleAnalyticsProvider } from "components/GoogleAnalyticsProvider";

type Props = {
  children: ReactNode;
  pageName: string;
  breadcrumbs?: PageType[];
  showFooter?: boolean;
};

/**
 * 共通レイアウト
 * @param Props
 * @returns
 */
const Layout: FC<Props> = ({
  children,
  pageName,
  breadcrumbs,
  showFooter = true,
}: {
  children: ReactNode;
  pageName: string;
  breadcrumbs?: PageType[];
  showFooter?: boolean;
}) => {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{pageName} | ココロザシポータル</title>
        </Helmet>
      </HelmetProvider>
      <Header currentPageName={pageName} />
      <GoogleAnalyticsProvider>
        <main className="bg-[var(--main-bg-color)] min-h-screen">
          {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}

          {children}
        </main>
        {showFooter && <Footer />}
      </GoogleAnalyticsProvider>
    </>
  );
};

export default Layout;
