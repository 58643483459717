import { FC, memo } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const MessageMarkdown: FC<{ content: string }> = memo(({ content }) => {
  return (
    <span className="text-gray-900 mb-4">
      <ReactMarkdown
        remarkPlugins={[remarkGfm as any]}
        components={{
          hr: () => null,
        }}
        className="prose"
      >
        {content}
      </ReactMarkdown>
    </span>
  );
});

export default MessageMarkdown;
