import { FC, useRef, useEffect } from "react";
import { useFaqBotContext } from "contexts/FaqBotContext";
import { KOKOROZASHI_BLUE_ICON_FILE_NAME, FAQ_AI_QUESTION } from "common/constants";
import MessageMarkdown from "./MessageMarkdown";
import MessageMarkdownTyping from "./MessageMarkdownTyping";

const MessageList: FC = () => {
  const { messages, isTyping, showInitialState, currentResponse, handleSendMessage } =
    useFaqBotContext();
  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  }, [messages, currentResponse]);

  return (
    <>
      {showInitialState ? (
        <div className="flex flex-col items-center justify-center sm:pt-0 flex-grow space-y-6 mx-6 mb-[20%] sm:mx-28 sm:mb-0">
          <img src={KOKOROZASHI_BLUE_ICON_FILE_NAME} alt="AI Icon" className="w-16 h-16 mb-6" />
          <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
            {FAQ_AI_QUESTION.map((question, index) => (
              <button
                key={index}
                className="flex flex-col items-center p-4 rounded-lg border border-gray-300 bg-white hover:bg-gray-100 transition-colors text-left shadow-sm"
                onClick={() => handleSendMessage(question)}
              >
                <span className="text-gray-800">{question}</span>
              </button>
            ))}
          </div>
        </div>
      ) : (
        <div
          ref={containerRef}
          className="flex-grow px-6 pt-4 pb-8 sm:px-20 sm:pt-20 sm:pb-10 overflow-y-scroll "
        >
          {messages.map((message) => (
            <div
              key={message.id}
              className={`mb-4 flex ${message.isUser ? "justify-end" : "justify-start"}`}
            >
              {!message.isUser ? (
                <div className="flex items-start">
                  <img
                    src={KOKOROZASHI_BLUE_ICON_FILE_NAME}
                    alt="AI Icon"
                    className="w-5 h-5 mr-5 sm:w-6 sm:h-6"
                  />

                  <MessageMarkdown content={message.content} />
                </div>
              ) : (
                <span className="inline-block max-w-lg p-3 rounded-lg bg-gray-300 text-gray-900 text-left mb-4 leading-7 whitespace-pre-wrap">
                  {message.content}
                </span>
              )}
            </div>
          ))}
          {isTyping && (
            <div className="flex items-start">
              <img
                src={KOKOROZASHI_BLUE_ICON_FILE_NAME}
                alt=""
                className="w-5 h-5 mr-5 sm:w-6 sm:h-6"
              />
              <MessageMarkdownTyping />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default MessageList;
