import {
  createContext,
  useContext,
  useState,
  ReactNode,
  ChangeEvent,
  FC,
} from "react";
import {
  sendAuthenticatedRequest,
  removeSourceReferences,
} from "../hooks/faqBotHooks";

type Message = {
  id: number;
  content: string;
  isUser: boolean;
};

// FaqBotContextで管理する状態や関数のプロパティを定義
interface FaqBotContextProps {
  messages: Message[]; // チャットメッセージ履歴
  inputValue: string; // ユーザーが入力した質問のテキスト
  isTyping: boolean; // AIが応答中かどうかのフラグ
  showInitialState: boolean; // 初期状態でサンプル質問を表示するフラグ
  currentResponse: string; // 現在のストリーミング中のAI応答
  threadId: string; // OpenAIのスレッドID
  setInputValue: (value: string) => void;
  addMessage: (message: Message) => void;
  setIsTyping: (isTyping: boolean) => void;
  setShowInitialState: (show: boolean) => void;
  setCurrentResponse: (response: string) => void;
  setThreadId: (id: string) => void;
  handleSendMessage: (question: string) => void;
  handleInputChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

const FaqBotContext = createContext<FaqBotContextProps | undefined>(undefined);

export const FaqBotProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [showInitialState, setShowInitialState] = useState<boolean>(true);
  const [currentResponse, setCurrentResponse] = useState<string>("");
  const [threadId, setThreadId] = useState<string>("");

  const addMessage = (message: Message) => {
    setMessages((prevMessages) => [...prevMessages, message]);
  };

  /**
   * 入力された質問をOpenAIに送信
   * @param question 質問
   **/
  const handleSendMessage = async (question: string) => {
    const content = question || inputValue.trim();
    if (!content) return;

    addMessage({ id: Date.now(), content, isUser: true });
    setIsTyping(true);
    setCurrentResponse("");
    setShowInitialState(false);
    setInputValue("");

    try {
      await sendAuthenticatedRequest(
        content,
        threadId,
        processStreamedResponse
      );
    } catch (error) {
      console.error("Error sending message:", error);
    }
    setIsTyping(false);
  };

  /**
   * テキスト入力変更時の処理
   * @param e 入力されたテキスト
   **/
  const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
  };

  /**
   * OpenAIからのストリーミングを処理
   * @param chunk ストリーミングデータ
   * データの形式: { status: xxx, data: xxx }\n\n
   **/
  const processStreamedResponse = (chunk: string) => {
    const parsedData = JSON.parse(chunk);
    const { status, data } = parsedData;

    switch (status) {
      // ストリーミング開始
      case "start":
        setThreadId(data);
        break;
      case "processing":
        const cleanedData = removeSourceReferences(data);
        setCurrentResponse((prev) => prev + cleanedData);
        break;
      // ストリーミング受信中
      case "end":
        setCurrentResponse((prev) => {
          addMessage({ id: Date.now(), content: prev, isUser: false });
          return prev;
        });
        setIsTyping(false);
        break;
      // エラー発生時
      case "error":
        setIsTyping(false);
        addMessage({
          id: Date.now(),
          content: "Error received from API",
          isUser: false,
        });
        break;
      default:
        console.error("Unknown status:", status);
    }
  };

  return (
    <FaqBotContext.Provider
      value={{
        messages,
        inputValue,
        isTyping,
        showInitialState,
        currentResponse,
        threadId,
        setInputValue,
        addMessage,
        setIsTyping,
        setShowInitialState,
        setCurrentResponse,
        setThreadId,
        handleSendMessage,
        handleInputChange,
      }}
    >
      {children}
    </FaqBotContext.Provider>
  );
};

export const useFaqBotContext = () => {
  const context = useContext(FaqBotContext);
  if (!context) {
    throw new Error("useFaqBotContext must be used within a FaqBotProvider");
  }
  return context;
};
